<template>
  <comprasList />
</template>

<script>

const comprasList = () => import('@/layouts/components/Compras/ComprasList.vue')
// aa

export default {
  components: {
    comprasList,
  },
}
</script>

<style>

</style>
